<template>
  <img
    :src="fullSrc"
    :style="`max-width: ${props.width || 'none'}; max-height: ${props.height || 'none'}`"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { imgixUrl, type ImgixUrlOptions } from '@/services/imgix'

const props = withDefaults(defineProps<{ src: string } & ImgixUrlOptions>(), {
  fit: 'clamp',
  dpr: '2'
})

const fullSrc = computed(() => imgixUrl(props.src, props))
</script>
