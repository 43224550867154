<template>
  <AntInput
    :value="props.value || ''"
    @update:value="(val) => emit('update:value', val)"
    :placeholder="placeholder"
    :disabled="formState?.disabled || disabled"
    type="text"
    :maxlength="props.maxLength"
    class="gum-text-input"
    @keypress="onlyNumberKey($event)"
  />
</template>

<script setup lang="ts">
import AntInput from 'ant-design-vue/es/input'
import { useFormState } from './global-state'

const formState = useFormState()

const props = defineProps<{
  value: string | null | undefined
  placeholder?: string
  disabled?: boolean
  maxLength: number
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string): void
}>()

const onlyNumberKey = (e: KeyboardEvent) => {
  if (e.charCode === 0 || /\d/.test(String.fromCharCode(e.charCode))) {
    return true
  } else {
    e.preventDefault()
  }
}

// const wearMask = (e: KeyboardEvent) => {
//   let target = e.target as HTMLInputElement,
//     position = target.selectionEnd,
//     length = target.value.length

//   target.value = target.value
//     .replace(/[^\dA-Z]/g, '')
//     .replace(/(.{4})/g, '$1 ')
//     .trim()
//   target.selectionEnd = position! +=
//     target.value.charAt(position! - 1) === ' ' &&
//     target.value.charAt(length - 1) === ' ' &&
//     length !== target.value.length
//       ? 1
//       : 0
// }
</script>
