<template>
  <GumTextInput
    :value="props.value || ''"
    @update:value="(val) => emit('update:value', val)"
    :maxlength="5"
    @keypress="allowedCharactersOnly($event)"
  />
</template>

<script setup lang="ts">
import { GumTextInput } from './components'

const props = defineProps<{
  value: string | null | undefined
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string): void
}>()

const allowedCharactersOnly = (e: KeyboardEvent) => {
  if (e.charCode === 0 || /[\d/]/.test(e.key)) {
    return true
  } else {
    e.preventDefault()
  }
}
</script>
