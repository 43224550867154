export type ImgixUrlOptions = {
  width?: number
  height?: number
  fit?: 'fill' | 'crop' | 'clamp'
  dpr?: '1' | '2' | '3'
}

export const imgixUrl = (url: string, options: ImgixUrlOptions = {}) => {
  const builder = new URLSearchParams('')

  if (options.width) builder.append('w', String(options.width))
  if (options.height) builder.append('h', String(options.height))
  if (options.fit) builder.append('fit', options.fit)
  if (options.dpr) builder.append('dpr', options.dpr)

  return `${url}?${builder.toString()}`
}
